import ComputerLanguage from '../types/ComputerLanguage';
import { ComputerLanguageParts } from '../generated/ComputerLanguageParts';

const asComputerLanguage = (parts: ComputerLanguageParts): ComputerLanguage => {
  return {
    id: parts.computer_language_id,
    language: parts.language,
  };
};

export { asComputerLanguage };
