import React from 'react';
import Select, { ValueType } from 'react-select';
import { blockReasons } from '../../data/blockReasons';
import { selectorStyles } from '../../theme';



export interface BlockReasonSelectorProps {
  value?: null | string;
  onChange?: (value: string) => void;
}

const BLOCK_REASONS = blockReasons.map((reason) => ({
  value: reason,
  label: reason,
}));

const BlockReasonSelector: React.FC<BlockReasonSelectorProps> = ({ value = null, onChange = () => undefined }) => {
  const handleOnChange = (option: ValueType<{ label: string; value: string }>) => {
    onChange((option as { label: string; value: string }).value);
  };

  return (
    <Select
      value={BLOCK_REASONS.find((o) => o.value === value)}
      options={BLOCK_REASONS}
      styles={selectorStyles}
      onChange={handleOnChange}
    />
  );
};
export default BlockReasonSelector;
