import InterestRegion from '../types/InterestRegion';
import { InterestRegionParts } from '../generated/InterestRegionParts';

const asInterestRegion = (interestRegionParts: InterestRegionParts): InterestRegion => {
  return {
    id: interestRegionParts.interest_region_id,
    region: interestRegionParts.region,
  };
};

export { asInterestRegion };
