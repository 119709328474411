import React from 'react';
import styled from 'styled-components';

import { colors } from '../../theme';
import { FilterOption } from '../../types/FilterValueType';
import Checkbox from '../Checkbox';

const StyledMenuOption = styled.div`
  display: grid;
  grid-template-columns: 2.4rem auto;
  grid-template-rows: 2.4rem;
  grid-template-areas: 'selector value';

  margin: 0.2rem 0;

  font-size: 1.4rem;
  line-height: 2.4rem;
`;

const StyledGroupName = styled.div`
  grid-area: value;

  margin-left: 1rem;
  margin-right: auto;

  max-width: 27rem;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  cursor: pointer;
`;

const StyledSelector = styled(Checkbox)`
  grid-area: selector;

  margin: auto;
  border-color: ${colors.black};

  transform: scale(0.75);

  cursor: pointer;
`;

interface MultiCheckFilterOptionProps {
  checked: boolean;
  option: FilterOption;
  toggleOption: (add: boolean) => void;
}

const MultiCheckFilterOption: React.FC<MultiCheckFilterOptionProps> = ({ option, toggleOption, checked }) => {
  const handleOnClick = () => {
    toggleOption(!checked);
  };

  return (
    <StyledMenuOption>
      <StyledSelector checked={checked} onChange={handleOnClick} />
      <StyledGroupName onClick={handleOnClick}>{option.displayValue}</StyledGroupName>
    </StyledMenuOption>
  );
};

export default MultiCheckFilterOption;
