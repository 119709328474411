import React from 'react';
import { WhereQueryInterface } from '../../../queries/queryUtils';
import { RangeAndBool } from '../../../types/FilterValueType';
import TestType, { TestOutOf } from '../../../types/TestType';
import { useFilter } from '../../../utils/useFilter';

import BooleanFilterItem from '../BooleanFilterItem';
import RangeFilter from '../RangeFilter';

export interface SATFilterFilterProps {
  name: string;
  nameNonApplicable: string;
}
export const generateQuery = ({
  range: [minValue, maxValue],
  bool: nonApplicable,
}: RangeAndBool): WhereQueryInterface => {
  const querySATFilterFilter: WhereQueryInterface = {
    enabled: true,
    operator: '_and',

    whereQuery: {
      _or: [
        {
          test_scores: {
            _and: [{ test: { _eq: TestType.SAT } }, { score: { _gte: minValue } }, { score: { _lte: maxValue } }],
          },
        },
        ...(!nonApplicable ? [{ _not: { test_scores: { test: { _eq: TestType.SAT } } } }] : []),
      ],
    },
  };

  return querySATFilterFilter;
};

const SATFilterFilter: React.FC<SATFilterFilterProps> = ({ name }) => {
  const { storeFilterStatus, filterValue, clearFilter } = useFilter(name);

  const rangeFilterValue = (filterValue as RangeAndBool)?.range || [0, TestOutOf.SAT];
  const boolFilterValue = ((filterValue as RangeAndBool)?.bool as boolean) || false;

  const setFilterValueRange = (range: [number, number]) =>
    storeFilterStatus(generateQuery({ range, bool: boolFilterValue }), { range, bool: boolFilterValue });

  const setFilterValueBool = (bool: boolean) =>
    storeFilterStatus(generateQuery({ range: rangeFilterValue, bool }), { range: rangeFilterValue, bool });

  return (
    <RangeFilter
      precision={0}
      filterTitle={TestType.SAT}
      setFilterValue={setFilterValueRange}
      clearFilter={clearFilter}
      range={[0, TestOutOf.SAT]}
      value={rangeFilterValue}
      step={1}
    >
      <BooleanFilterItem on={boolFilterValue} filterTitle={'Hide non applicable'} setFilterValue={setFilterValueBool} />
    </RangeFilter>
  );
};

export default SATFilterFilter;
