import React from 'react';
import { Redirect } from 'react-router-dom';

import { Paths } from 'routes';
import { useAuth } from 'elitegrad-common/src/utils/react-auth';
import SpinnerIsoType from 'elitegrad-common/src/components/SpinnerIsoType';

const AuthCallback: React.FC = () => {
  const { user, error, authenticating } = useAuth();

  if (authenticating) {
    return <SpinnerIsoType fullPage text="Authenticating" />;
  }

  if (error) {
    return <Redirect to={Paths.authLogin} />;
  }

  if (user) {
    return <Redirect to={Paths.root} />;
  }

  return null;
};

export default AuthCallback;
