import React from 'react';
import styled from 'styled-components';
import WithSeparator from 'react-with-separator';

import MultiCheckFilterOption from './MultiCheckFilterOption';
import FilterTitle from './FilterTitle';
import { colors } from '../../theme';
import { FilterOption } from '../../types/FilterValueType';
import HorizontalRuler from '../HorizontalRuler';
import Spinner from '../Spinner';
import SearchInputText from '../../components/SearchInputText';

const StyledSearchInputText = styled(SearchInputText)`
  margin-bottom: 1.3rem;

  border: 1px solid ${colors.gray_light};
  border-radius: 8px;

  background-color: ${colors.white};
`;

const StyledGroupOptions = styled.div`
  width: 100%;
  max-height: 50rem;

  display: inline-block;

  overflow-y: auto;
  overflow-x: hidden;
`;

const StyledNoneAll = styled.div`
  float: right;

  margin-left: 1rem;

  color: ${colors.red};
  cursor: pointer;
  text-decoration: underline;
`;

const StyledHorizontalRules = styled(HorizontalRuler)`
  border: 1px solid ${colors.gray_extraLight};
`;

export interface MultiCheckFilterAsyncProps {
  filterTitle: string;
  options: FilterOption[];
  textFilter: string;
  loading: boolean;

  clearFilter: () => void;
  setFilterValue: (options: FilterOption[]) => void;
  setTextFilter: (value: string) => void;
  setSelectedOptions: (options: FilterOption[]) => void;
  selectedOptions: FilterOption[];
}

const MultiCheckFilterAsync: React.FC<MultiCheckFilterAsyncProps> = ({
  options,
  setFilterValue,
  setTextFilter,
  filterTitle,
  textFilter,
  loading,
  setSelectedOptions,
  selectedOptions,
  clearFilter,
}) => {
  const generateToggleOption = (option: FilterOption) => {
    return (add: boolean) => {
      const newSelectedOptions = add
        ? [...selectedOptions, option]
        : selectedOptions.filter(({ id }) => id !== option.id);
      setSelectedOptions(newSelectedOptions);
      if (newSelectedOptions.length) setFilterValue(newSelectedOptions);
      else clearFilter();
    };
  };

  const handleOnClickAll = () => {
    const allOptions = [...options, ...selectedOptions];
    setSelectedOptions(allOptions);
    setFilterValue(allOptions);
  };

  const handleOnClickNone = () => {
    setSelectedOptions([]);
    clearFilter();
  };

  return (
    <div>
      <FilterTitle title={filterTitle} />
      <StyledSearchInputText placeholder="Search" value={textFilter} onChange={setTextFilter} />
      <span>
        <StyledNoneAll onClick={handleOnClickNone}>None</StyledNoneAll>
        <StyledNoneAll onClick={handleOnClickAll}>All</StyledNoneAll>
      </span>

      <StyledGroupOptions>
        <WithSeparator separator={<StyledHorizontalRules />}>
          {selectedOptions.map((option) => {
            return (
              <MultiCheckFilterOption
                checked={true}
                option={option}
                key={option.id}
                toggleOption={generateToggleOption(option)}
              />
            );
          })}
        </WithSeparator>
        {selectedOptions.length > 0 && <StyledHorizontalRules />}

        {loading ? (
          <Spinner />
        ) : (
          <WithSeparator separator={<StyledHorizontalRules />}>
            {options.map((option) => {
              return (
                <MultiCheckFilterOption
                  checked={false}
                  option={option}
                  key={option.id}
                  toggleOption={generateToggleOption(option)}
                />
              );
            })}
          </WithSeparator>
        )}
      </StyledGroupOptions>
    </div>
  );
};

export default MultiCheckFilterAsync;
