import React from 'react';
import styled from 'styled-components';

import Test from '../../types/Test';
import TestType from '../../types/TestType';
import CandidateTestScoreDetails from './CandidateTestScoreDetails';

const StyledTestScores = styled.div<{ big: boolean }>`
  font-size: ${({ big }) => (big ? '1.6rem' : '1.2rem')};

  min-height: 5.5rem;
  padding: 0.5rem;
`;

const StyledTestType = styled.span`
  line-height: 1.5rem;
  font-weight: 700;
`;

const StyledScore = styled.span`
  margin-left: 0.8rem;

  line-height: 1.5rem;
  font-weight: 700;
`;

const StyledOutOf = styled.span`
  margin-right: 0.8rem;

  line-height: 1.5rem;
  font-weight: 500;
`;

interface CandidateTestScoresProps {
  test: Test;
  big?: boolean;
}

const CandidateTestScores: React.FC<CandidateTestScoresProps> = ({ test, big = false }) => {
  const isSat = test.type === TestType.SAT;
  const isSATorACT = isSat || test.type === TestType.ACT;
  const details = test.details;
  return (
    <StyledTestScores big={big}>
      <StyledTestType>{test.type}:</StyledTestType>
      <StyledScore>{test.score}</StyledScore>
      {isSat && <StyledOutOf>/{test.outof}</StyledOutOf>}
      {isSATorACT && <CandidateTestScoreDetails big={big} details={details} />}
    </StyledTestScores>
  );
};

export default CandidateTestScores;
