import gql from 'graphql-tag';

import { CandidateParts, CandidatePartsAdmin } from './fragments.gql';

export default gql`
  mutation UpdateCandidateBlock(
    $admin: Boolean!
    $candidateId: String!
    $blocked_reason: String = ""
    $blocked: Boolean!
  ) {
    update_grad_profile(
      _set: { blocked: $blocked, blocked_reason: $blocked_reason }
      where: { user_id: { _eq: $candidateId } }
    ) {
      returning {
        ... on grad_profile @include(if: $admin) {
          ...CandidatePartsAdmin
        }

        ... on grad_profile @skip(if: $admin) {
          ...CandidateParts
        }
      }
    }
  }
  ${CandidateParts}
  ${CandidatePartsAdmin}
`;
