import React from 'react';
import styled from 'styled-components';

import Switch from './Switch';
import { usePhoto } from '../utils/usePhoto';

const StyledShowPhotosSwitch = styled.div`
  display: flex;
  align-items: center;
`;

const StyledText = styled.div`
  margin-right: 1rem;

  font-size: 1.2rem;
  line-height: 1.2rem;
`;

const ShowPhotosSwitch: React.FC = () => {
  const { showPhoto, setShowPhoto } = usePhoto();

  return (
    <StyledShowPhotosSwitch>
      <StyledText>Show candidate picture</StyledText>
      <Switch on={showPhoto} onChange={setShowPhoto} />
    </StyledShowPhotosSwitch>
  );
};

export default ShowPhotosSwitch;
