import React from 'react';

import { WhereQueryInterface } from '../../../queries/queryUtils';
import { RangeType } from '../../../types/FilterValueType';
import TestType, { TestOutOf } from '../../../types/TestType';
import { useFilter } from '../../../utils/useFilter';
import RangeFilter from '../RangeFilter';

export interface GMATFilterProps {
  name: string;
}

export const generateQuery = ([minValue, maxValue]: RangeType): WhereQueryInterface => {
  const scoreFilter = Object({ score: { _lte: maxValue, _gte: minValue } });
  const queryGMATFilter: WhereQueryInterface = {
    enabled: true,
    operator: '_and',
    whereQuery: { test_scores: { test: { _eq: TestType.GMAT }, _and: scoreFilter } },
  };

  return queryGMATFilter;
};

const GMATFilter: React.FC<GMATFilterProps> = ({ name }) => {
  const { storeFilterStatus, filterValue, clearFilter } = useFilter(name);

  const setFilterValue = (range: [number, number]) => storeFilterStatus(generateQuery(range), range);

  return (
    <RangeFilter
      precision={0}
      filterTitle={TestType.GMAT}
      range={[0, TestOutOf.GMAT]}
      value={filterValue as RangeType}
      step={10}
      setFilterValue={setFilterValue}
      clearFilter={clearFilter}
    />
  );
};

export default GMATFilter;
