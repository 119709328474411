export enum TestACTDetailsType {
  ENGLISH = 'math',
  MATHEMATICS = 'reading',
  SCIENCE = 'science',
  REASONING = 'reasoning',
  WRITING = 'writing',
  UNKNOWN = 'unknown',
}
export enum TestSATDetailsType {
  MATH = 'math',
  READING = 'reading',
  WRITING = 'writing',
  UNKNOWN = 'unknown',
}

export enum UnknownDetailsType {
  UNKNOWN = 'unknown',
}

export enum TestACTDetailsOutof {
  ENGLISH = 36,
  MATHEMATICS = 36,
  SCIENCE = 36,
  REASONING = 36,
  WRITING = 12,
}

export enum TestSATDetailsOutof {
  MATH = 800,
  READING = 800,
  WRITING = 800,
}
