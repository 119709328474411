import React, { useCallback } from 'react';
import { useMutation } from '@apollo/client';

import UPDATE_BLOCK from '../../queries/updateCandidateBlock.gql';

import BlockMenuModal from './BlockMenuModal';
import UnBlockMenuModal from './UnBlockMenuModal';
import { UpdateCandidateBlock, UpdateCandidateBlockVariables } from '../../generated/UpdateCandidateBlock';

export interface ToggleBlockMenuModalControllerProps {
  candidateBlocked: boolean;
  blockReason: string | null;
  candidateId: string;
  setModalOpen: (status: boolean) => void;
  refetch: () => void;
}

const ToggleBlockMenuModalController: React.FC<ToggleBlockMenuModalControllerProps> = ({
  setModalOpen,
  candidateId,
  candidateBlocked,
  blockReason,
  refetch,
}) => {
  const [updateCandidateBlock] = useMutation<UpdateCandidateBlock, UpdateCandidateBlockVariables>(UPDATE_BLOCK);

  const onBlock = useCallback(
    (reason: string) => {
      updateCandidateBlock({
        variables: { admin: true, candidateId: candidateId, blocked: true, blocked_reason: reason },
      });
      if (refetch) refetch();
    },
    [updateCandidateBlock, candidateId, refetch],
  );

  const onUnblock = useCallback(() => {
    updateCandidateBlock({ variables: { admin: true, candidateId: candidateId, blocked: false } });
    if (refetch) refetch();
  }, [updateCandidateBlock, candidateId, refetch]);

  return (
    <>
      {candidateBlocked ? (
        <UnBlockMenuModal setModalOpen={setModalOpen} unBlock={onUnblock} reason={blockReason || 'unknown reason'} />
      ) : (
        <BlockMenuModal setModalOpen={setModalOpen} onClick={onBlock} />
      )}
    </>
  );
};

export default ToggleBlockMenuModalController;
