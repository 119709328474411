import Group from '../types/Group';
import { GroupParts } from '../generated/GroupParts';
import { GroupMembershipParts } from '../generated/GroupMembershipParts';

const fromGroupMembership = (parts: GroupMembershipParts): Group | null => {
  const groupParts = parts.grad_group;

  return groupParts ? asGroup(groupParts) : null;
};

const asGroup = (groupParts: GroupParts): Group => {
  return {
    name: groupParts.group_name,
    id: groupParts.grad_group_id,
  };
};

export { asGroup, fromGroupMembership };
