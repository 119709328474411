import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import { Paths } from './routes';
import { useAuth } from 'elitegrad-common/src/utils/react-auth';
import { AuthCallback, AuthCheckingSession, AuthDebug, AuthLogin, AuthLogout } from './views/login';
import Candidate from 'views/candidate';
import { CandidatesController } from 'views/candidates';
import { FilterContextProvider } from 'elitegrad-common/src/utils/useFilter';

const App: React.FC = () => {
  const { checkingSession, loginRequired, user } = useAuth();

  if (checkingSession) {
    return <AuthCheckingSession />;
  }

  if (loginRequired) {
    return <AuthLogin />;
  }

  return (
    <Switch>
      <Route exact path={Paths.authCallback} component={AuthCallback} />
      <Route exact path={Paths.authLogin} component={AuthLogin} />
      <Route exact path={Paths.authDebug} component={AuthDebug} />
      <Route exact path={Paths.authLogout} component={AuthLogout} />
      <Route
        exact
        path={Paths.candidates}
        component={() => (
          <FilterContextProvider>
            <CandidatesController />
          </FilterContextProvider>
        )}
      />{' '}
      <Route exact path={Paths.candidate} component={Candidate} />
      <Route exact path={Paths.root}>
        {user && <Redirect to={Paths.candidates} />}
      </Route>
    </Switch>
  );
};

export default App;
