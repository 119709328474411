import React, { useState } from 'react';

import styled from 'styled-components';
import { colors } from '../../theme';
import { RangeType } from '../../types/FilterValueType';
import { usePopper } from '../../utils/usePopper';
import RangeInput from '../RangeInput';

import FilterTitle from './FilterTitle';

const StyledFilterApply = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledClearApply = styled.div`
  grid-area: save;

  color: ${colors.red};

  text-decoration: underline;

  cursor: pointer;
`;

export interface RangeFilterProps {
  range: RangeType;
  value: RangeType | null;
  step: number;
  filterTitle: string;
  precision: number;
  setFilterValue: (value: [number, number]) => void;
  clearFilter: () => void;
}

const RangeFilter: React.FC<RangeFilterProps> = ({
  setFilterValue,
  range,
  value,
  step,
  filterTitle,
  precision,
  clearFilter,
  children,
}) => {
  const [rangeValue, setRangeValue] = useState<RangeType>(value || range);

  const { togglePopper } = usePopper(filterTitle);
  const handleClear = () => {
    clearFilter();
    togglePopper();
  };

  const handleApplyFilter = () => {
    setFilterValue(rangeValue);
    togglePopper();
  };

  const handleOnChange = (minValue: number, maxValue: number) => setRangeValue([minValue, maxValue]);

  return (
    <div>
      <FilterTitle title={filterTitle} />
      {children}
      <RangeInput precision={precision} onChange={handleOnChange} range={range} value={rangeValue} step={step} />
      <StyledFilterApply>
        <StyledClearApply onClick={handleClear}>Clear</StyledClearApply>
        <StyledClearApply onClick={handleApplyFilter}>Apply</StyledClearApply>
      </StyledFilterApply>
    </div>
  );
};

export default RangeFilter;
