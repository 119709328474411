import styled from 'styled-components';

import colors from '../theme/colors';

const VerticalDivider = styled.div`
  width: 1px;
  margin: 0 0.5rem;

  background: ${colors.gray_light};
`;

export default VerticalDivider;
