import InterestFirm from '../types/InterestFirm';
import { InterestFirmSelectorParts } from '../generated/InterestFirmSelectorParts';

const selectionAsInterestFirm = (interestFirmSelectionParts: InterestFirmSelectorParts): InterestFirm => {
  return {
    id: interestFirmSelectionParts.interest_firm_selection_id,
    firmType: interestFirmSelectionParts.value,
  };
};

export { selectionAsInterestFirm };
