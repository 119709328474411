import React, { useState, useContext } from 'react';

const initialContext = {
  showPhoto: true,
  setShowPhoto: () => undefined,
};

export const UsePhotoContext = React.createContext<{
  showPhoto: boolean;
  setShowPhoto: (showPhoto: boolean) => void;
}>(initialContext);

export const usePhoto = (): {
  showPhoto: boolean;
  setShowPhoto: (showPhoto: boolean) => void;
} => useContext(UsePhotoContext);

export const UsePhotoProvider: React.FC = ({ children }) => {
  const [showPhoto, setShowPhoto] = useState(initialContext.showPhoto);

  return (
    <UsePhotoContext.Provider
      value={{
        showPhoto,
        setShowPhoto,
      }}
    >
      {children}
    </UsePhotoContext.Provider>
  );
};
