import InterestRole from '../types/InterestRole';
import { InterestRoleParts } from '../generated/InterestRoleParts';

const asInterestRole = (interestRoleParts: InterestRoleParts): InterestRole => {
  return {
    id: interestRoleParts.interest_role_id,
    roleType: interestRoleParts.role,
  };
};

export { asInterestRole };
