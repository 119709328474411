import React, { useState, SyntheticEvent, useCallback } from 'react';
import styled, { css } from 'styled-components';
import WithSeparator from 'react-with-separator';

import HorizontalRuler from 'elitegrad-common/src/components/HorizontalRuler';
import Candidate from 'elitegrad-common/src/types/Candidate';
import DownloadCVMenuOptionController from 'elitegrad-common/src/components/candidates/DownloadCVMenuOptionController';
import { MenuMoreVertical } from 'elitegrad-common/src/assets/vectors';
import ToggleBlockMenuOption from 'elitegrad-common/src/components/candidates/ToggleBlockMenuOption';
import colors from 'elitegrad-common/src/theme/colors';

const StyledDotMenuIcon = styled(MenuMoreVertical)<{ props: { lay: boolean } }>`
  z-index: 1;

  width: 2.4rem;
  height: 2rem;

  transition: transform 0.2s ease-in-out;

  ${({ props }) =>
    props.lay &&
    css`
      transform: rotate(90deg);
    `}
`;

const StyledMenu = styled.div`
  position: relative;
  grid-area: menu;
`;

const StyledOpenMenu = styled.div`
  position: absolute;
  z-index: 1;
  top: 2.4rem;
  right: 0;

  display: flex;
  flex-direction: column;

  padding: 0.5rem;
  min-width: 20rem;

  background: ${colors.white};
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
`;

const CandidateCardDotMenu: React.FC<{ candidate: Candidate; refetch: () => void }> = ({ candidate, refetch }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const handlePropagation = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
  }, []);

  const handleMenuClick = () => setMenuOpen((m) => !m);

  const handleOnBlur = () => setMenuOpen(false);

  const closeMenu = () => setMenuOpen(false);

  return (
    <StyledMenu onClick={handlePropagation} onMouseLeave={handleOnBlur}>
      <StyledDotMenuIcon onClick={handleMenuClick} props={{ lay: menuOpen }} />
      {menuOpen && (
        <StyledOpenMenu>
          <WithSeparator separator={<HorizontalRuler />}>
            <DownloadCVMenuOptionController onClick={closeMenu} candidateId={candidate.userId} />
            {candidate.blocked !== undefined && candidate.blockedReason !== undefined && (
              <ToggleBlockMenuOption
                refetch={refetch}
                candidateBlocked={candidate.blocked}
                candidateId={candidate.userId}
                reason={candidate.blockedReason}
              />
            )}
          </WithSeparator>
        </StyledOpenMenu>
      )}
    </StyledMenu>
  );
};

export default CandidateCardDotMenu;
