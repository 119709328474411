import React from 'react';
import styled from 'styled-components';
import { colors } from '../../theme';

const StyledFilterTitleH2 = styled.div`
  margin: 0.7rem 0 0;
  font-size: 1.4rem;
  line-height: 1.7rem;
  font-weight: 500;
  text-align: left;

  color: ${colors.gray_dark};
`;

const FilterTitleH2: React.FC<{ title: string }> = ({ title }) => {
  return <StyledFilterTitleH2>{title}</StyledFilterTitleH2>;
};

export default FilterTitleH2;
