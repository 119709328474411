import gql from 'graphql-tag';

import { GroupParts } from './fragments.gql';

export default gql`
  query GetGroups {
    recruit_grad_group {
      ...GroupParts
    }
  }
  ${GroupParts}
`;
