import gql from 'graphql-tag';

import { InterestJobTypeSelectorParts } from './fragments.gql';

export default gql`
  query GetInterestJobTypeSelection($job_type: String) {
    grad_interest_job_type_selection(where: { value: { _ilike: $job_type } }, limit: 10) {
      ...InterestJobTypeSelectorParts
    }
  }
  ${InterestJobTypeSelectorParts}
`;
