import React, { Ref } from 'react';
import styled, { css } from 'styled-components';

import { colors } from '../theme';

export interface SwitchProps {
  on?: boolean;
  onChange?: (on: boolean) => void;
}

const StyledSwitch = styled.div`
  position: relative;
  display: inline-block;

  width: 3.8rem;
  height: 1.8rem;

  cursor: pointer;
`;

const StyledArea = styled.div<{ checked: boolean }>`
  position: relative;
  top: 0.1rem;
  left: 0.1rem;

  width: 3.6rem;
  height: 1.6rem;

  border: none;
  border-radius: 1.6rem;

  background-color: ${colors.gray_light};

  transition-property: all;
  transition-timing-function: ease-in;
  transition-duration: 0.5s;
  transition-delay: 0.3s;

  ${({ checked }) =>
    checked &&
    css`
      background-color: ${colors.red};
    `}
`;

const StyledButton = styled.div<{ checked: boolean }>`
  position: absolute;
  top: 0;
  left: 0;

  width: 1.8rem;
  height: 100%;

  border: solid 1px ${colors.gray_light};
  border-radius: 50%;

  background-color: ${colors.white};

  ${({ checked }) =>
    checked
      ? css`
          transform: translateX(2rem);
          transition-duration: 0.5s;
        `
      : css`
          transform: translateX();
          transition-duration: 0.5s;
        `}
`;

const Switch: React.FC<SwitchProps> = ({ on = false, onChange = () => undefined }) => {
  return (
    <StyledSwitch onClick={() => onChange(!on)}>
      <StyledArea checked={!!on} />
      <StyledButton checked={on} />
    </StyledSwitch>
  );
};

interface HookFormSwitchProps {
  name: string;
  register: Ref<HTMLInputElement>;
  enabled: boolean;
}

export const HookFormSwitch: React.FC<HookFormSwitchProps> = ({ name, register, enabled = false }) => {
  return (
    <StyledSwitch>
      <input name={name} ref={register} type="checkbox" hidden />
      <StyledArea checked={!!enabled} />
      <StyledButton checked={enabled} />
    </StyledSwitch>
  );
};

export default Switch;
