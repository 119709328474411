import InterestRole from '../types/InterestRole';
import { InterestRoleSelectorParts } from '../generated/InterestRoleSelectorParts';

const selectionAsInterestRole = (interestRoleParts: InterestRoleSelectorParts): InterestRole => {
  return {
    id: interestRoleParts.interest_role_selection_id,
    roleType: interestRoleParts.value,
  };
};

export { selectionAsInterestRole };
