import React from 'react';
import styled from 'styled-components';

import { colors } from '../theme';
import { AcademicHonor } from '../assets/vectors';

const StyledAcademicHonorTag = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;

  padding: 0.3rem 0.4rem 0.2rem;

  border: 1px solid ${colors.gray_light};
  border-radius: 6px;
  background-color: ${colors.gray_extraLight};

  font-size: 1rem;
  line-height: 1.1rem;
  color: ${colors.gray};
`;

const StyledIconAcademicHonor = styled(AcademicHonor)`
  width: 1rem;
  height: 1rem;

  vertical-align: middle;
`;

const StyledLabel = styled.span`
  margin-left: 0.8rem;
`;

export interface AcademicHonorTagProps {
  awardName: string;
}

const AcademicHonorTag: React.FC<AcademicHonorTagProps> = ({ awardName, ...props }) => {
  return (
    <StyledAcademicHonorTag {...props}>
      <StyledIconAcademicHonor />
      <StyledLabel>{awardName}</StyledLabel>
    </StyledAcademicHonorTag>
  );
};

export default AcademicHonorTag;
