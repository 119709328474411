import React, { Ref } from 'react';
import styled, { css } from 'styled-components';

import colors from '../theme/colors';

const StyledCheckbox = styled.span`
  position: relative;

  width: 2rem;
  height: 2rem;
`;

interface StyledCustomCheckboxProps {
  checked?: boolean;
}

const StyledCustomCheckbox = styled.span<StyledCustomCheckboxProps>`
  position: absolute;
  top: 0;
  left: 0;

  width: 2rem;
  height: 2rem;

  border-radius: 2px;
  border: solid 2px ${colors.gray_dark};

  cursor: pointer;

  :after {
    position: absolute;
    content: '';
    top: 0rem;
    left: 0.4rem;
    height: 0;
    width: 0;

    border-radius: 4px;
    border: solid ${colors.gray_dark};
    border-width: 0 3px 3px 0;
    transform: rotate(0deg) scale(0);
    opacity: 1;

    ${({ checked }) =>
      checked &&
      css`
        transform: rotate(45deg) scale(1);
        width: 0.4rem;
        height: 1rem;
        background-color: transparent;
        border-radius: 0;
      `}
  }
`;

export interface CheckboxProps {
  checked?: boolean;
  onChange?: (checked: boolean) => void;
}

const Checkbox: React.FC<CheckboxProps> = ({ checked, onChange = () => undefined, ...props }) => {
  return (
    <StyledCheckbox {...props}>
      <StyledCustomCheckbox checked={checked} onClick={() => onChange(!checked)} />
    </StyledCheckbox>
  );
};

const StyledCustomHookCheckboxContainer = styled.span`
  position: relative;

  width: 2rem;
  height: 2rem;
`;

const StyledCustomHookCheckbox = styled.input<StyledCustomCheckboxProps>`
  position: absolute;
  top: 0;
  left: 0;

  width: 2rem;
  height: 2rem;

  border-radius: 2px;
  border: solid 2px ${colors.gray_dark};

  cursor: pointer;

  :after {
    position: absolute;
    content: '';
    top: 0rem;
    left: 0.4rem;
    height: 0;
    width: 0;

    border-radius: 4px;
    border: solid ${colors.gray_dark};
    border-width: 0 3px 3px 0;
    transform: rotate(0deg) scale(0);
    opacity: 1;

    ${({ checked }) =>
      checked &&
      css`
        transform: rotate(45deg) scale(1);
        width: 0.4rem;
        height: 1rem;
        background-color: transparent;
        border-radius: 0;
      `}
  }
`;

interface HookCheckboxProps {
  name: string;
  register: Ref<HTMLInputElement>;
}

export const HookCheckbox: React.FC<HookCheckboxProps> = ({ name, register }) => {
  return (
    <StyledCustomHookCheckboxContainer>
      <StyledCustomHookCheckbox name={name} ref={register} type="checkbox" />
    </StyledCustomHookCheckboxContainer>
  );
};

export default Checkbox;
