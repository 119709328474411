import InterestRegion from '../types/InterestRegion';
import { InterestRegionSelectorParts } from '../generated/InterestRegionSelectorParts';

const selectionAsInterestRegion = (interestFirmSelectionParts: InterestRegionSelectorParts): InterestRegion => {
  return {
    id: interestFirmSelectionParts.interest_region_selection_id,
    region: interestFirmSelectionParts.value,
  };
};

export { selectionAsInterestRegion };
