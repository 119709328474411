import React from 'react';
import styled from 'styled-components';
import { ChevronDown, Calendar } from 'elitegrad-common/src/assets/vectors';
import colors from 'elitegrad-common/src/theme/colors';
import { useFilterGetter } from 'elitegrad-common/src/utils/useFilter';
import FilterItem from 'elitegrad-common/src/components/filters/FilterItem';
import FiltersGroup from 'elitegrad-common/src/components/filters/FiltersGroup';
import {
  NameFilter,
  LastNameFilter,
  InterestJobTypesToggleFilter,
  BlockedFilter,
  AthleteFilter,
  UniversityFilter,
  GroupedDegreeFilter,
  UndergradDegreeDateFilter,
  GraduateDegreeDateFilter,
  GPAFilter,
  MajorFilter,
  RelevantYearsOfWorkFilter,
  ProfessionalDesignationFilter,
  LanguageFilter,
  ComputerLanguageFilter,
  SATFilterDetailed,
  ACTFilter,
  GMATFilter,
  GREFilter,
  InterestFirmsFilter,
  InterestJobTypesFilter,
  InterestRolesFilter,
  InterestRegionsFilter,
  CombineFilter,
} from 'elitegrad-common/src/components/filters/queryFilters';
import ViewTitle from 'elitegrad-common/src/components/ViewTitle';
import FilterClear from 'elitegrad-common/src/components/filters/FilterClear';

const StyledFilterSideBar = styled.div`
  height: 100vh;
  width: 17rem;
  min-width: 17rem;
  padding: 1.8rem 1.4rem;

  background-color: ${colors.gray_light_l1};

  overflow-y: scroll;
  overflow-x: hidden;

  & > * {
    margin: 2rem 0;
  }

  & > *:first-child {
    margin: 0;
  }

  & > *:last-child {
    margin-bottom: 0;
  }
`;

const ChevronDownRed = styled(ChevronDown)`
  max-height: 2.4rem;
  max-width: 2.4rem;

  min-height: 2.4rem;
  min-width: 2.4rem;

  & * {
    stroke: ${colors.red};
  }
`;

const StyledTitle = styled.div`
  display: flex;
  justify-content: space-around;
`;

const FilterSideBar: React.FC = () => {
  const { filterActiveWithId } = useFilterGetter();

  return (
    <StyledFilterSideBar>
      <StyledTitle>
        <ViewTitle>Filters</ViewTitle>
        <FilterClear />
      </StyledTitle>

      <FiltersGroup name="Quick Filters">
        <CombineFilter name={'CombineFilter'} />
        <NameFilter name={'NameFilter'} />
        <LastNameFilter name={'LastNameFilter'} />
        <InterestJobTypesToggleFilter name={'InterestJobTypesToggleFilter'} />
        <BlockedFilter name={'BlockedFilter'}></BlockedFilter>
      </FiltersGroup>

      <FiltersGroup name="Academic">
        <FilterItem text="Athlete" icon={ChevronDownRed} filterActive={filterActiveWithId('AthleteFilter')}>
          <AthleteFilter name={'AthleteFilter'} />
        </FilterItem>
        <FilterItem text="Schools" icon={ChevronDownRed} filterActive={filterActiveWithId('UniversityFilter')}>
          <UniversityFilter name={'UniversityFilter'} />
        </FilterItem>
        <FilterItem text="Degree" icon={ChevronDownRed} filterActive={filterActiveWithId('DegreeFilter')}>
          <GroupedDegreeFilter name={'DegreeFilter'} />
        </FilterItem>
        <FilterItem
          text="Year Undergrad Graduation"
          icon={Calendar}
          filterActive={filterActiveWithId('UndergradDegreeDateFilter')}
        >
          <UndergradDegreeDateFilter name={'UndergradDegreeDateFilter'} />
        </FilterItem>
        <FilterItem
          text="Year Advanced Degree Graduation"
          icon={Calendar}
          filterActive={filterActiveWithId('GraduateDegreeDateFilter')}
        >
          <GraduateDegreeDateFilter name={'GraduateDegreeDateFilter'} />
        </FilterItem>
        <FilterItem text="GPA" icon={ChevronDownRed} filterActive={filterActiveWithId('GPAFilter')}>
          <GPAFilter name={'GPAFilter'} nameNonApplicable={'GPAFilterNotApplicable'} />
        </FilterItem>
        <FilterItem text="Major" icon={ChevronDownRed} filterActive={filterActiveWithId('MajorFilter')}>
          <MajorFilter name={'MajorFilter'} />
        </FilterItem>

        <FilterItem
          text="Work Experience / Prior Internships"
          icon={ChevronDownRed}
          filterActive={filterActiveWithId('RelevantYearsOfWorkFilter')}
        >
          <RelevantYearsOfWorkFilter name={'RelevantYearsOfWorkFilter'} />
        </FilterItem>

        <FilterItem
          text="Professional Designation"
          icon={ChevronDownRed}
          filterActive={filterActiveWithId('ProfessionalDesignationFilter')}
        >
          <ProfessionalDesignationFilter name={'ProfessionalDesignationFilter'} />
        </FilterItem>

        <FilterItem text="Language" icon={ChevronDownRed} filterActive={filterActiveWithId('LanguageFilter')}>
          <LanguageFilter name={'LanguageFilter'} />
        </FilterItem>
        <FilterItem
          text="Technical Language"
          icon={ChevronDownRed}
          filterActive={filterActiveWithId('ComputerLanguageFilter')}
        >
          <ComputerLanguageFilter name={'ComputerLanguageFilter'} />
        </FilterItem>
      </FiltersGroup>

      <FiltersGroup name="Test">
        <FilterItem text="SAT" icon={ChevronDownRed} filterActive={filterActiveWithId('SATFilter')}>
          <SATFilterDetailed name={'SATFilterDetailed'} />
        </FilterItem>
        <FilterItem text="ACT" icon={ChevronDownRed} filterActive={filterActiveWithId('ACTFilter')}>
          <ACTFilter name={'ACTFilter'} nameNonApplicable={'ACTFilterNotApplicable'} />
        </FilterItem>
        <FilterItem text="GMAT" icon={ChevronDownRed} filterActive={filterActiveWithId('GMATFilter')}>
          <GMATFilter name={'GMATFilter'} />
        </FilterItem>
        <FilterItem text="GRE" icon={ChevronDownRed} filterActive={filterActiveWithId('GREFilter')}>
          <GREFilter name={'GREFilter'} nameNonApplicable={'GREFilterNotApplicable'} />
        </FilterItem>
      </FiltersGroup>

      <FiltersGroup name="Interest">
        <FilterItem text="Firm Types" icon={ChevronDownRed} filterActive={filterActiveWithId('InterestFirmsFilter')}>
          <InterestFirmsFilter name={'InterestFirmsFilter'} />
        </FilterItem>
        <FilterItem text="Jobs" icon={ChevronDownRed} filterActive={filterActiveWithId('InterestJobTypesFilter')}>
          <InterestJobTypesFilter name={'InterestJobTypesFilter'} />
        </FilterItem>
        <FilterItem text="Roles" icon={ChevronDownRed} filterActive={filterActiveWithId('InterestRolesFilter')}>
          <InterestRolesFilter name={'InterestRolesFilter'} />
        </FilterItem>
        <FilterItem text="Regions" icon={ChevronDownRed} filterActive={filterActiveWithId('InterestRegionsFilter')}>
          <InterestRegionsFilter name={'InterestRegionsFilter'} />
        </FilterItem>
      </FiltersGroup>
    </StyledFilterSideBar>
  );
};

export default FilterSideBar;
