import React from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';

import { PAGE_SIZE, INITIAL_OFFSET } from 'elitegrad-common/src/components/pagination/PaginationState';
import { asCandidatesWithProfileImages } from 'elitegrad-common/src/transformers/candidates';
import { usePhoto } from 'elitegrad-common/src/utils/usePhoto';

import GET_CANDIDATES from 'elitegrad-common/src/queries/getCandidates.gql';
import { GetCandidates, GetCandidatesVariables } from 'elitegrad-common/src/generated/GetCandidates';
import GET_PROFILE_IMAGES_BY_USER_IDS from 'elitegrad-common/src/queries/getProfileImagesByUserIds.gql';
import {
  GetProfileImagesByUserIds,
  GetProfileImagesByUserIdsVariables,
} from 'elitegrad-common/src/generated/GetProfileImagesByUserIds';
import { generateWhereQuery } from 'elitegrad-common/src/queries/queryUtils';
import { useFilterQueries, usePagination } from 'elitegrad-common/src/utils/useFilter';

import Candidates from './Candidates';

const CandidatesController: React.FC = () => {
  const { setOffSet, getOffset } = usePagination();

  const { getFilterQueries } = useFilterQueries();
  const dynamicQuery = Object.keys(getFilterQueries()).length > 0 ? generateWhereQuery(getFilterQueries()) : null;

  const getCandidatesVariables: GetCandidatesVariables = {
    ...(dynamicQuery && { dynamicQuery }),
    limit: PAGE_SIZE,
    offset: getOffset(),
    admin: true,
  };

  const [fetchImages, { data: imagesData }] = useLazyQuery<
    GetProfileImagesByUserIds,
    GetProfileImagesByUserIdsVariables
  >(GET_PROFILE_IMAGES_BY_USER_IDS);

  const { data, loading, refetch } = useQuery<GetCandidates, GetCandidatesVariables>(GET_CANDIDATES, {
    variables: getCandidatesVariables,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (showPhoto && data.grad_profile.length) {
        const candidatesUserIds = data.grad_profile.map((c) => c.user_id);

        fetchImages({
          variables: {
            userIds: candidatesUserIds,
          },
        });
      }
    },
  });

  const { showPhoto } = usePhoto();

  const candidates = asCandidatesWithProfileImages(
    data?.grad_profile || [],
    showPhoto ? imagesData?.grad_profile || [] : [],
  );

  const candidateQueryCount = data?.grad_profile_aggregate?.aggregate?.count || 0;

  return (
    <Candidates
      refetch={refetch}
      pagination={{
        count: candidateQueryCount,
        limit: getCandidatesVariables.limit || PAGE_SIZE,
        offset: getCandidatesVariables.offset || INITIAL_OFFSET,
      }}
      onChangePaginationOffset={setOffSet}
      candidates={candidates}
      loadingCandidates={loading}
    />
  );
};

export default CandidatesController;
