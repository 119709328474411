import styled from 'styled-components';

import { Lens } from '../assets/images';

import TextInput from './TextInput';

const StyledTextInput = styled(TextInput).attrs({
  greyish: true,
})`
  padding-left: 3.5rem;

  background-image: url(${Lens});
  background-repeat: no-repeat;
  background-position: 1rem center;
`;

export default StyledTextInput;
