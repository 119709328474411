import { ConcentrationParts } from '../generated/ConcentrationParts';
import Concentration from '../types/Concentration';
import ConcentrationType from '../types/ConcentrationType';

const asConcentrationType = (type: string): ConcentrationType => {
  if ((Object.values(ConcentrationType) as string[]).includes(type)) {
    return type as ConcentrationType;
  }

  // eslint-disable-next-line no-console
  console.warn(`Unknown concentration type: ${type}`);
  return ConcentrationType.UNKNOWN;
};

const asConcentration = (parts: ConcentrationParts): Concentration => {
  return {
    id: parts.concentration_id,
    name: parts.val,
    type: asConcentrationType(parts.type),
    degreeId: parts.degree_id,
    userId: parts.user_id,
  };
};

export { asConcentration };
