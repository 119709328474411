import React, { useState } from 'react';
import styled from 'styled-components';
import WithSeparator from 'react-with-separator';

import SearchInputText from '../../components/SearchInputText';
import MultiCheckFilterOption from './MultiCheckFilterOption';
import FilterTitle from './FilterTitle';
import { colors } from '../../theme';
import { FilterOption } from '../../types/FilterValueType';
import HorizontalRuler from '../HorizontalRuler';

const StyledSearchInputText = styled(SearchInputText)`
  margin-bottom: 1.3rem;

  border: 1px solid ${colors.gray_light};
  border-radius: 8px;

  background-color: ${colors.white};
`;

const StyledGroupOptions = styled.div`
  width: 100%;
  max-height: 50rem;

  display: inline-block;

  overflow-y: auto;
  overflow-x: hidden;
`;

const StyledNoneAll = styled.div`
  float: right;

  margin-left: 1rem;
  margin-bottom: 0.5rem;

  color: ${colors.red};
  cursor: pointer;
  text-decoration: underline;
`;

const StyledHorizontalRules = styled(HorizontalRuler)`
  border: 1px solid ${colors.gray_extraLight};
`;

export interface MultiCheckFilterProps {
  filterTitle: string;
  options: FilterOption[];
  defaultOptions: FilterOption[];
  setFilterValue: (options: FilterOption[]) => void;
  clearFilter: () => void;
  allSelection?: boolean;
  sortSelected?: boolean;
}

const MultiCheckFilter: React.FC<MultiCheckFilterProps> = ({
  options,
  setFilterValue,
  filterTitle,
  defaultOptions,
  clearFilter,
  allSelection = true,
  sortSelected = true,
}) => {
  const [textFilter, setTextFilter] = useState('');
  const [selectedOptions, setSelectedOptions] = useState<FilterOption[]>(defaultOptions || []);

  const filteredOptions = options.filter((u) => {
    const filteredByText = u.displayValue.toLowerCase().includes(textFilter.toLowerCase());
    const filteredBySelection = selectedOptions.some(({ id }) => u.id === id);

    return !filteredBySelection && filteredByText;
  });

  const generateToggleOption = (option: FilterOption) => {
    return (add: boolean) => {
      const newSelectedOptions = add
        ? [...selectedOptions, option]
        : selectedOptions.filter(({ id }) => id !== option.id);
      setSelectedOptions(newSelectedOptions);
      if (newSelectedOptions.length) setFilterValue(newSelectedOptions);
      else clearFilter();
    };
  };

  const handleOnClickAll = () => {
    setSelectedOptions(options);
    setFilterValue([]);
  };

  const handleOnClickNone = () => {
    setSelectedOptions([]);
    setFilterValue([]);
    clearFilter();
  };

  return (
    <div>
      <FilterTitle title={filterTitle} />
      <StyledSearchInputText placeholder="Search" value={textFilter} onChange={setTextFilter} />
      <span>
        <StyledNoneAll onClick={handleOnClickNone}>None</StyledNoneAll>
        {allSelection && <StyledNoneAll onClick={handleOnClickAll}>All</StyledNoneAll>}
      </span>
      <StyledGroupOptions>
        {sortSelected && (
          <>
            <WithSeparator separator={<StyledHorizontalRules />}>
              {selectedOptions.map((option) => {
                return (
                  <MultiCheckFilterOption
                    checked={true}
                    option={option}
                    key={option.id}
                    toggleOption={generateToggleOption(option)}
                  />
                );
              })}
            </WithSeparator>
            {selectedOptions.length > 0 && <StyledHorizontalRules />}
          </>
        )}

        <WithSeparator separator={<StyledHorizontalRules />}>
          {(sortSelected ? filteredOptions : options).map((option) => {
            const checked = selectedOptions.some(({ id }) => option.id === id);

            return (
              <MultiCheckFilterOption
                checked={checked}
                option={option}
                key={option.id}
                toggleOption={generateToggleOption(option)}
              />
            );
          })}
        </WithSeparator>
      </StyledGroupOptions>
    </div>
  );
};

export default MultiCheckFilter;
