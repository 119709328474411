const AwardProfessionalDesignation = {
  CPA: 'CPA',
  CAIA: 'CAIA',
  CFA: 'CFA',
  CQF: 'CQF',
  FRM: 'FRM',
  CRM: 'CRM',
};

export default AwardProfessionalDesignation;
