import React from 'react';
import styled from 'styled-components';

import HorizontalRuler from 'elitegrad-common/src/components/HorizontalRuler';
import colors from 'elitegrad-common/src/theme/colors';

import {
  CandidateNoTests,
  CandidatePreviousDegree,
  CandidateNoDegrees,
  CandidateWorkExperience,
  CandidateNoWorkExperiences,
  CandidateCurrentDegree,
} from 'elitegrad-common/src/components/candidate';

import CollapsibleCardSection from 'elitegrad-common/src/components/candidates/CollapsibleCardSection';

import CandidateType from 'elitegrad-common/src/types/Candidate';
import { getDegreesSortedByCompleteDate } from 'elitegrad-common/src/types/Degree';
import { getTestsSortedByPriority } from 'elitegrad-common/src/types/Test';
import TypeWorkExperience, { sortByDate as sortWorkExperiencesByDate } from 'elitegrad-common/src/types/WorkExperience';
import CandidateTestScoreSat from 'elitegrad-common/src/components/candidate/CandidateTestScoreSat';
import CandidateTestScoreOthers from 'elitegrad-common/src/components/candidate/CandidateTestScoreOthers';

import CandidateCardHeader from './CandidateCardHeader';
import CandidateCardInterests from 'elitegrad-common/src/components/candidate/CandidateCardInterests';

const StyledCandidateCardBorder = styled.div<{ selected?: boolean }>`
  width: 28.5rem;
  padding: 0.5rem 0.5rem 2.2rem;

  border: none;
  border-radius: 8px;

  background-color: ${({ selected }) => (selected ? colors.green : colors.red)};
`;

const StyledCandidateCard = styled.div`
  position: relative;

  height: 100%;
  padding: 1rem;

  border: none;
  border-radius: 8px;

  background-color: ${colors.white};
`;

const StyledHorizontalRuler = styled(HorizontalRuler)`
  margin-bottom: 0.5rem;
`;

export interface CandidateCardProps {
  candidatePath: string;
  candidate: CandidateType;
  selected: boolean;
  editSelection?: (add: boolean) => void;
  refetch: () => void;
}

const CandidateCard: React.FC<CandidateCardProps> = ({ candidatePath, candidate, selected, refetch }) => {
  const [currentDegree, ...previousDegrees] = getDegreesSortedByCompleteDate(candidate.degrees);
  const workExperiences = sortWorkExperiencesByDate(candidate.workExperiences) as TypeWorkExperience[];
  const [satAct, ...otherTests] = getTestsSortedByPriority(candidate.tests);

  return (
    <StyledCandidateCardBorder selected={selected}>
      <StyledCandidateCard>
        <CandidateCardHeader
          currentDegree={currentDegree}
          refetch={refetch}
          candidate={candidate}
          selected={selected}
          candidatePath={candidatePath}
        />
        <StyledHorizontalRuler />
        {currentDegree && <CandidateCurrentDegree degree={currentDegree} />}

        <StyledHorizontalRuler />
        <CollapsibleCardSection title="Test score">
          {satAct && <CandidateTestScoreSat satTest={satAct} big={false} />}
          {otherTests.length > 0 && <CandidateTestScoreOthers tests={otherTests} big={false} />}
          {candidate.tests.length === 0 && <CandidateNoTests />}
        </CollapsibleCardSection>

        <StyledHorizontalRuler />
        <CandidateCardInterests interests={candidate.interestFirmTypes} />

        <StyledHorizontalRuler />
        <CollapsibleCardSection title="Work Experience / Prior Internships">
          {workExperiences.map((we) => (
            <CandidateWorkExperience workExperience={we} key={we.id} />
          ))}
          {workExperiences.length === 0 && <CandidateNoWorkExperiences />}
        </CollapsibleCardSection>
        <StyledHorizontalRuler />
        <CollapsibleCardSection title="Previous Degrees">
          {previousDegrees.map((d) => (
            <CandidatePreviousDegree degree={d} key={d.id} />
          ))}
          {previousDegrees.length === 0 && <CandidateNoDegrees />}
        </CollapsibleCardSection>
        <StyledHorizontalRuler />
      </StyledCandidateCard>
    </StyledCandidateCardBorder>
  );
};

export default CandidateCard;
