export const Paths = {
  root: '/',
  authCallback: '/auth/callback',
  authLogin: '/auth/login',
  authDebug: '/auth/debug',
  authLogout: '/auth/logout',
  candidates: '/candidates',
  candidate: '/candidates/:userId',
  home: '/candidates',
};

export const candidatePath = (userId: string): string => `/candidates/${userId}`;
