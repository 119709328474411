import InterestJobType from '../types/InterestJobType';
import { InterestJobTypeParts } from '../generated/InterestJobTypeParts';
import { asJobType } from './jobType';

const asInterestJobType = (interestJobTypeParts: InterestJobTypeParts): InterestJobType => {
  return {
    id: interestJobTypeParts.interest_job_type_id,
    jobType: asJobType(interestJobTypeParts.job_type),
  };
};

export { asInterestJobType };
