import TestType from './TestType';
import TestDetail from './TestDetail';

interface Test {
  id: string;
  type: TestType;
  score: number;
  outof: number | null;
  details: TestDetail[];
}

const getTestsSortedByPriority = (tests: Test[]): Test[] => {
  return tests.sort(({ type: typeA }, { type: typeB }) => {
    if (typeA === TestType.SAT) {
      return -1;
    } else if (typeB === TestType.SAT) {
      return 1;
    } else if (typeA === TestType.ACT) {
      return -1;
    } else if (typeB === TestType.ACT) {
      return 1;
    } else {
      return 0;
    }
  });
};

export { getTestsSortedByPriority };

export default Test;
