import React from 'react';
import styled, { css } from 'styled-components';

const StyledDotMenuOption = styled.div<{ disabled: boolean }>`
  height: 2.8rem;
  padding: 0.7rem 0;

  font-size: 1.2rem;
  line-height: 1.4rem;

  ${({ disabled }) =>
    disabled
      ? css`
          opacity: 0.5;
        `
      : css`
          cursor: pointer;
        `}
`;

interface CandidateCardDotMenuOptionProps {
  onClick: () => void;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  text: string;
  disabled?: boolean;
}

const CandidateCardDotMenuOption: React.FC<CandidateCardDotMenuOptionProps> = ({
  onClick,
  icon,
  text,
  disabled = false,
  children,
}) => {
  const StyledDotMenuOptionIcon = styled(icon)`
    width: 1.6rem;
    margin-right: 1rem;

    vertical-align: bottom;
  `;

  return (
    <>
      <StyledDotMenuOption disabled={disabled} onClick={onClick}>
        <StyledDotMenuOptionIcon />
        {text}
      </StyledDotMenuOption>
      {children}
    </>
  );
};

export default CandidateCardDotMenuOption;
