import InterestJobType from '../types/InterestJobType';
import { asJobType } from './jobType';
import { InterestJobTypeSelectorParts } from '../generated/InterestJobTypeSelectorParts';

const selectionAsInterestJobType = (interestJobTypeSelectionParts: InterestJobTypeSelectorParts): InterestJobType => {
  return {
    id: interestJobTypeSelectionParts.interest_job_type_selection_id,
    jobType: asJobType(interestJobTypeSelectionParts.value),
  };
};

export { selectionAsInterestJobType };
