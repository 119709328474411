import { TestSATDetailsOutof } from './TestDetailType';

enum TestType {
  ACT = 'ACT',
  SAT = 'SAT',
  GMAT = 'GMAT',
  GRE = 'GRE',
  LSAT = 'LSAT',
  MCAT = 'MCAT',
  UNKNOWN = 'UNKNOWN',
}

export enum TestOutOf {
  ACT = 36,
  SAT = TestSATDetailsOutof.MATH + TestSATDetailsOutof.READING + TestSATDetailsOutof.WRITING,
  GMAT = 800,
  GRE = 340,
  LSAT = 180,
  MCAT = 528,
}

export default TestType;
