import gql from 'graphql-tag';

import { CandidateParts, CandidatePartsAdmin } from './fragments.gql';

export default gql`
  query GetCandidate($admin: Boolean!, $userId: String!) {
    grad_profile(where: { user_id: { _eq: $userId } }) {
      ... on grad_profile @include(if: $admin) {
        ...CandidatePartsAdmin
      }

      ... on grad_profile @skip(if: $admin) {
        ...CandidateParts
      }
      profile_image
    }
  }
  ${CandidateParts}
  ${CandidatePartsAdmin}
`;
