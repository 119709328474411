import React from 'react';
import relevantYearsOfWorkExperience from '../../../data/relevantYearsOfWorkExperience';
import { WhereQueryInterface } from '../../../queries/queryUtils';
import { FilterOption } from '../../../types/FilterValueType';
import { useFilter } from '../../../utils/useFilter';
import MultiCheckFilter from '../MultiCheckFilter';

export interface RelevantYearsOfWorkFilterProps {
  name: string;
}

export const generateQuery = (options: FilterOption[]): WhereQueryInterface => {
  const values = options.map(({ filterValue }) => filterValue);
  const queryRelevantYearsOfWorkFilter: WhereQueryInterface = {
    enabled: !!values.length,
    operator: '_and',
    whereQuery: { years_of_relevant_work: { _in: values } },
  };

  return queryRelevantYearsOfWorkFilter;
};

const RelevantYearsOfWorkFilter: React.FC<RelevantYearsOfWorkFilterProps> = ({ name }) => {
  const { storeFilterStatus, filterValue, clearFilter } = useFilter(name);

  const setFilterValue = (options: FilterOption[]) => storeFilterStatus(generateQuery(options), options);

  const optionsWorkExperience: FilterOption[] = relevantYearsOfWorkExperience.map((year) => ({
    filterValue: year,
    displayValue: year,
    id: year,
  }));

  return (
    <MultiCheckFilter
      filterTitle={'Work Experience / Prior Internships'}
      options={optionsWorkExperience}
      defaultOptions={(filterValue as FilterOption[]) || []}
      setFilterValue={setFilterValue}
      clearFilter={clearFilter}
      sortSelected={false}
    />
  );
};

export default RelevantYearsOfWorkFilter;
