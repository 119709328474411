import JobType from '../types/JobType';

const asJobType = (jobType: string): JobType => {
  if ((Object.values(JobType) as string[]).includes(jobType)) {
    return jobType as JobType;
  }

  // eslint-disable-next-line no-console
  console.warn(`Unknown job type: ${jobType}`);
  return JobType.UNKNOWN;
};

export { asJobType };
