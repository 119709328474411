import React from 'react';
import styled from 'styled-components';

import { SquaresSpinner } from '../assets/vectors';

const StyledSpinner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledSpinnerSquares = styled(SquaresSpinner)`
  width: 1.8rem;
  height: 1.8rem;
`;

const Spinner: React.FC = () => {
  return (
    <StyledSpinner>
      <StyledSpinnerSquares />
    </StyledSpinner>
  );
};

export default Spinner;
