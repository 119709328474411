import DegreeSelection from '../types/DegreeSelection';
import DegreeLevel from '../types/DegreeLevel';
import { DegreeSelectorParts } from '../generated/DegreeSelectorParts';

const asDegreeLevel = (degreeLevel: string): DegreeLevel => {
  if ((Object.values(DegreeLevel) as string[]).includes(degreeLevel)) {
    return degreeLevel as DegreeLevel;
  }

  // eslint-disable-next-line no-console
  console.warn(`Unknown degree level: ${degreeLevel}`);
  return DegreeLevel.UNKNOWN;
};

const asDegreeSelection = (parts: DegreeSelectorParts): DegreeSelection => {
  return {
    id: parts.degree_selection_id,
    degreeLevel: asDegreeLevel(parts.degree_level),
    degreeName: parts.degree,
    description: parts.description,
  };
};

export { asDegreeSelection };
