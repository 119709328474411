import { TestACTDetailsType, TestSATDetailsType, UnknownDetailsType } from '../types/TestDetailType';
import { TestScoreDetailParts } from '../generated/TestScoreDetailParts';
import TestDetail from '../types/TestDetail';
import TestType from '../types/TestType';

const asTestDetailTypeSAT = (testDetailType: string): TestSATDetailsType => {
  if ((Object.values(TestSATDetailsType) as string[]).includes(testDetailType)) {
    return testDetailType as TestSATDetailsType;
  }

  // eslint-disable-next-line no-console
  console.warn(`Unknown SAT test detail type: ${testDetailType}`);
  return TestSATDetailsType.UNKNOWN;
};

const asTestDetailTypeACT = (testDetailType: string): TestACTDetailsType => {
  if ((Object.values(TestACTDetailsType) as string[]).includes(testDetailType)) {
    return testDetailType as TestACTDetailsType;
  }

  // eslint-disable-next-line no-console
  console.warn(`Unknown ACT test detail type: ${testDetailType}`);
  return TestACTDetailsType.UNKNOWN;
};

const asTestDetail = (parts: TestScoreDetailParts, testType: TestType): TestDetail => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let type: any = null;

  if (testType === TestType.ACT) type = asTestDetailTypeACT(parts.detail);
  else if (testType === TestType.SAT) type = asTestDetailTypeSAT(parts.detail);
  else {
    type = UnknownDetailsType.UNKNOWN;
    // eslint-disable-next-line no-console
    console.warn(`Unknown test detail for testType type: ${testType}`);
  }

  return {
    id: parts.test_score_detail_id,
    score: parts.score,
    outof: parts.outof,
    type: type,
  };
};

export { asTestDetail };
