import React, { useState } from 'react';

import styled, { css } from 'styled-components';
import { ChevronDown } from '../../../assets/vectors';
import { WhereQueryInterface } from '../../../queries/queryUtils';
import { colors } from '../../../theme';
import { RangeType, FilterValueType } from '../../../types/FilterValueType';
import { TestSATDetailsType, TestSATDetailsOutof } from '../../../types/TestDetailType';
import TestType, { TestOutOf } from '../../../types/TestType';
import { useFilter } from '../../../utils/useFilter';
import { usePopper } from '../../../utils/usePopper';
import RangeInput from '../../RangeInput';
import BooleanFilterItem from '../BooleanFilterItem';
import FilterTitle from '../FilterTitle';
import FilterTitleH2 from '../FilterTitleH2';

const StyledClearApply = styled.div`
  color: ${colors.red};

  text-decoration: underline;

  cursor: pointer;
`;

const StyledArrowIcon = styled(ChevronDown)<{ props: { up?: boolean } }>`
  width: 1.8rem;
  height: 1.8rem;

  ${({ props }) =>
    !!props.up &&
    css`
      transform: rotate(180deg);
    `}
`;

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 1.8rem;
  margin-bottom: 0.5rem;

  cursor: pointer;
`;

export interface SATFilterDetailedProps {
  name: string;
}

const rangeQuerySAT = (testName: string, range: RangeType | null) => ({
  _and: [
    ...(range != null
      ? [{ test: { _eq: testName } }, { score: { _gte: range[0] } }, { score: { _lte: range[1] } }]
      : []),
  ],
});

const rangeQuerySATDetail = (testName: string, range: RangeType | null) => ({
  test_score_details: {
    _and: [
      ...(range != null
        ? [{ detail: { _eq: testName } }, { score: { _gte: range[0] } }, { score: { _lte: range[1] } }]
        : []),
    ],
  },
});

export const generateQuery = (value: { [property: string]: FilterValueType }): WhereQueryInterface => {
  const { includeNonApplicable, satRange, satRangeMath, satRangeRead, satRangeWrite } = value;

  const nonApplicable = includeNonApplicable as boolean;
  const querySATFilterDetailed: WhereQueryInterface = {
    enabled: true,
    operator: '_and',

    whereQuery: {
      _or: [
        {
          test_scores: {
            _and: [
              rangeQuerySAT(TestType.SAT, satRange as RangeType),
              rangeQuerySATDetail(TestSATDetailsType.MATH, satRangeMath as RangeType),
              rangeQuerySATDetail(TestSATDetailsType.READING, satRangeRead as RangeType),
              rangeQuerySATDetail(TestSATDetailsType.WRITING, satRangeWrite as RangeType),
            ],
          },
        },
        ...(!nonApplicable ? [{ _not: { test_scores: { test: { _eq: TestType.SAT } } } }] : []),
      ],
    },
  };

  return querySATFilterDetailed;
};

const SATFilterDetailed: React.FC<SATFilterDetailedProps> = ({ name }) => {
  const { togglePopper } = usePopper(name);
  const { storeFilterStatus, filterValue, clearFilter } = useFilter(name);

  const [searchDetails, setSearchDetails] = useState(false);

  const { includeNonApplicable, satRange, satRangeMath, satRangeRead, satRangeWrite } = (filterValue as {
    [property: string]: FilterValueType;
  } | null) || {
    includeNonApplicable: false,
    satRange: null,
    satRangeMath: null,
    satRangeRead: null,
    satRangeWrite: null,
  };

  const filterValues = {
    satRange,
    ...(searchDetails
      ? { satRangeMath, satRangeRead, satRangeWrite }
      : {
          satRangeMath: null,
          satRangeRead: null,
          satRangeWrite: null,
        }),
    includeNonApplicable,
  };
  const setFilterValue = (rangeName: string, range: [number, number]) => {
    const newValues = { ...filterValues, [rangeName]: range };
    storeFilterStatus(generateQuery(newValues), newValues);
  };

  const setFilterValueBool = (includeNonApplicable: boolean) => {
    const newValues = { ...filterValues, includeNonApplicable };
    storeFilterStatus(generateQuery(newValues), newValues);
  };
  const handleClear = () => {
    clearFilter();
    togglePopper();
  };

  const handleSearchDetails = () => {
    const filterValues = {
      satRange,
      ...(!searchDetails
        ? { satRangeMath, satRangeRead, satRangeWrite }
        : {
            satRangeMath: null,
            satRangeRead: null,
            satRangeWrite: null,
          }),
      includeNonApplicable,
    };
    setSearchDetails((e) => !e);

    storeFilterStatus(generateQuery(filterValues), filterValues);
  };

  return (
    <div>
      <StyledClearApply onClick={handleClear}>Clear</StyledClearApply>
      <BooleanFilterItem
        on={includeNonApplicable as boolean}
        filterTitle={'Hide non applicable'}
        setFilterValue={setFilterValueBool}
      />
      <FilterTitleH2 title={'SAT'} />
      <RangeInput
        precision={0}
        onChange={(minValue: number, maxValue: number) => setFilterValue('satRange', [minValue, maxValue])}
        range={[0, TestOutOf.SAT]}
        value={satRange as RangeType | null}
        step={1}
      />
      <StyledHeader onClick={handleSearchDetails}>
        <FilterTitle title={'Search Details'} />
        <StyledArrowIcon props={{ up: searchDetails }} />
      </StyledHeader>
      {searchDetails && (
        <>
          <FilterTitleH2 title={'Math'} />
          <RangeInput
            precision={0}
            onChange={(minValue: number, maxValue: number) => setFilterValue('satRangeMath', [minValue, maxValue])}
            range={[0, TestSATDetailsOutof.MATH]}
            value={satRangeMath as RangeType}
            step={1}
          />
          <FilterTitleH2 title={'Reading'} />
          <RangeInput
            precision={0}
            onChange={(minValue: number, maxValue: number) => setFilterValue('satRangeRead', [minValue, maxValue])}
            range={[0, TestSATDetailsOutof.READING]}
            value={satRangeRead as RangeType}
            step={1}
          />
          <FilterTitleH2 title={'Writting'} />
          <RangeInput
            precision={0}
            onChange={(minValue: number, maxValue: number) => setFilterValue('satRangeWrite', [minValue, maxValue])}
            range={[0, TestSATDetailsOutof.WRITING]}
            value={satRangeWrite as RangeType}
            step={1}
          />
        </>
      )}
    </div>
  );
};

export default SATFilterDetailed;
