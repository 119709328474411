import React, { SyntheticEvent, useState, useCallback, useMemo } from 'react';
import WithSeparator from 'react-with-separator';
import styled, { css } from 'styled-components';

import HorizontalRuler from '../components/HorizontalRuler';
import { ChevronDown } from '../assets/vectors';
import Checkbox from '../components/Checkbox';
import { colors } from '../theme';

export interface CollapsibleSectionProps {
  title: string;
  defaultCollapsed?: boolean;
  isSelectedAll?: boolean;
  onSelectAll?: () => void;
}

const StyledTitle = styled.div`
  margin: 1rem 0;

  font-size: 1.4rem;
  font-weight: 800;
  line-height: 1.4rem;
  text-transform: capitalize;
`;

const StyledArrowIcon = styled(ChevronDown)<{ props: { up?: boolean } }>`
  width: 1.8rem;
  height: 1.8rem;

  ${({ props }) =>
    !!props.up &&
    css`
      transform: rotate(180deg);
    `}
`;

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 1.8rem;
  margin-bottom: 0.5rem;
`;

const StyledHorizontalRuler = styled(HorizontalRuler)`
  margin: 0 0 1rem;
`;

const StyledChildrenContainer = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;

  max-height: 16rem;

  margin: 1rem 0;
`;

const StyledCollapsibleSection = styled.div`
  overflow: hidden;

  margin: 1.3rem 0;
`;

const HeaderCheckbox = styled(Checkbox)`
  grid-area: selector;

  margin: 0 1rem;
  border-color: ${colors.black};

  transform: scale(0.75);

  cursor: pointer;
`;

const HeaderTitleContainer = styled.div`
  display: flex;
  align-items: center;

  & > span {
    margin: 0 1rem 0 0.2rem;
  }
`;

const CollapsibleSection: React.FC<CollapsibleSectionProps> = ({
  title,
  isSelectedAll,
  onSelectAll,
  defaultCollapsed = false,
  children,
}) => {
  const [collapsed, setCollapsed] = useState(defaultCollapsed);

  const arrayChildren = React.Children.toArray(children);

  const handleOnClick = useCallback((e: SyntheticEvent) => {
    setCollapsed((c) => !c);
    e.stopPropagation();
    e.preventDefault();
  }, []);

  const separator = useMemo<React.ReactElement>(() => <StyledHorizontalRuler />, []);

  return (
    <StyledCollapsibleSection>
      <StyledHeader>
        <HeaderTitleContainer>
          <HeaderCheckbox checked={isSelectedAll} onChange={onSelectAll} />
          <StyledTitle>{title}</StyledTitle>
        </HeaderTitleContainer>
        {arrayChildren.length > 0 && <StyledArrowIcon props={{ up: !collapsed }} onClick={handleOnClick} />}
      </StyledHeader>
      <StyledChildrenContainer>
        {arrayChildren.length > 0 && !collapsed && <WithSeparator separator={separator}>{arrayChildren}</WithSeparator>}
      </StyledChildrenContainer>
    </StyledCollapsibleSection>
  );
};

export default CollapsibleSection;
