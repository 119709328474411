import { DegreeParts } from '../generated/DegreeParts';
import Degree, { DEFAULT_GPA_OUT_OF } from '../types/Degree';
import DegreeLevel from '../types/DegreeLevel';
import { parseBackendStartDate, parseBackendCompleteDate } from '../utils/dateUtils';
import { asAward } from './awards';
import { asConcentration } from './concentrations';
import { asUniversity, asFillUniversity } from './universities';

const asDegreeLevel = (degreeLevel: string): DegreeLevel => {
  if ((Object.values(DegreeLevel) as string[]).includes(degreeLevel)) {
    return degreeLevel as DegreeLevel;
  }

  // eslint-disable-next-line no-console
  console.warn(`Unknown degree level: ${degreeLevel}`);
  return DegreeLevel.UNKNOWN;
};

const asDegree = (parts: DegreeParts): Degree => {
  const startDate = parseBackendStartDate(parts);
  const completeDate = parseBackendCompleteDate(parts);

  const awards = parts.awards.map(asAward);
  const concentrations = parts.concentrations.map(asConcentration);
  const university = parts.university ? asUniversity(parts.university) : null;
  const fillUniversity = parts.fill_in_university ? asFillUniversity(parts.fill_in_university) : null;
  const gpaOutOf = parts.gpa ? parts.gpa_outof || DEFAULT_GPA_OUT_OF : null;
  const gpaNotApplicable = parts.gpa_not_applicable || !parts.gpa || parts.gpa === 0;
  return {
    id: parts.degree_id,
    startDate,
    completeDate,
    degreeLevel: asDegreeLevel(parts.degree_level),
    degreeName: parts.degree,
    gpa: gpaNotApplicable ? null : parts.gpa,
    gpaOutOf: gpaNotApplicable ? null : gpaOutOf,
    awards,
    concentrations,
    university: university || fillUniversity,
    userId: parts.user_id,
    gpaNotApplicable,
  };
};

export { asDegree };
