import React, { useState, RefObject } from 'react';
import { Range, getTrackBackground } from 'react-range';
import styled from 'styled-components';

import colors from '../theme/colors';
import NumericInput from 'react-numeric-input';
import { RangeType } from '../types/FilterValueType';

enum RangeCorner {
  Left = 'left',
  Right = 'right',
}

const StyledRangeArea = styled.div`
  display: grid;

  grid-template-columns: 5rem auto 5rem;
  grid-template-areas: 'left track right';
  gap: 2rem;

  height: 3rem;
  width: 100%;
  margin: 0.5rem 0 1rem;

  background-color: ${colors.white};
  padding: 0;

  & > * {
    margin: auto;
  }
`;

interface StyledRangeAreaLabelProps {
  gridarea: RangeCorner;
}

interface StyledTrackProps {
  minRange: number;
  maxRange: number;
  values: number[];
  ref: RefObject<HTMLDivElement>;
}

const StyledTrack = styled.div<StyledTrackProps>`
  grid-area: track;
  height: 5px;
  width: 100%;
  border-radius: 4px;
  background: ${({ minRange, maxRange, values }) =>
    getTrackBackground({
      values,
      colors: [colors.gray_light, colors.red, colors.gray_light],
      min: minRange,
      max: maxRange,
    })};

  align-self: center;
`;

const StyledThumb = styled.div`
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  background-color: ${colors.gray_l1};
  border: 1px solid ${colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 6px ${colors.gray_l1};
`;

const StyledInput = styled(NumericInput).attrs({
  strict: true,
  style: false,
  snap: true,
})<StyledRangeAreaLabelProps>`
  grid-area: ${({ gridarea }) => gridarea};

  margin: auto;
  max-width: 5rem;
  min-height: 3rem;

  border: 1px solid #dcdcdc;
  box-sizing: border-box;
  border-radius: 8px;

  text-align: center;
`;

export interface RangeInputProps {
  value: RangeType | null;
  range: RangeType;
  step: number;
  precision: number;
  onChange: (minValue: number, maxValue: number) => void;
}

const RangeInput: React.FC<RangeInputProps> = ({ precision, range: [minRange, maxRange], value, step, onChange }) => {
  const [minValue, maxValue] = value || [minRange, maxRange];
  const [rangeValues, setRangeValues] = useState([minValue, maxValue]);

  const handleMaxOnChange = (value: number | null) => {
    if (value === null || value === rangeValues[0]) return;
    setRangeValues([rangeValues[0], value]);
    onChange(rangeValues[0], value);
  };

  const handleMinOnChange = (value: number | null) => {
    if (value === null || value === rangeValues[1]) return;
    setRangeValues([value, rangeValues[1]]);
    onChange(value, rangeValues[1]);
  };

  const handleOnChange = (values: number[]) => {
    setRangeValues(values);
  };

  const handleOnFinalChange = (values: number[]) => {
    const [minValue, maxValue] = values;
    onChange(minValue, maxValue);
  };

  return (
    <Range
      values={rangeValues}
      step={step}
      min={minRange}
      max={maxRange}
      onChange={handleOnChange}
      onFinalChange={handleOnFinalChange}
      renderTrack={({ props, children }) => (
        <StyledRangeArea onMouseDown={props.onMouseDown} onTouchStart={props.onTouchStart} style={props.style}>
          <StyledInput
            precision={precision}
            step={step}
            min={minRange}
            max={maxRange}
            value={rangeValues[0]}
            onChange={handleMinOnChange}
            gridarea={RangeCorner.Left}
          />
          <StyledInput
            precision={precision}
            step={step}
            min={minRange}
            max={maxRange}
            value={rangeValues[1]}
            onChange={handleMaxOnChange}
            gridarea={RangeCorner.Right}
          />
          <StyledTrack minRange={minRange} maxRange={maxRange} values={rangeValues} ref={props.ref}>
            {children}
          </StyledTrack>
        </StyledRangeArea>
      )}
      renderThumb={({ props }) => <StyledThumb {...props} style={props.style}></StyledThumb>}
    />
  );
};

export default RangeInput;
