import InterestFirm from '../types/InterestFirm';
import { InterestFirmParts } from '../generated/InterestFirmParts';

const asInterestFirm = (interestFirmParts: InterestFirmParts): InterestFirm => {
  return {
    id: interestFirmParts.interest_firm_id,
    firmType: interestFirmParts.firm_type,
  };
};

export { asInterestFirm };
