import React from 'react';

import { WhereQueryInterface } from '../../../queries/queryUtils';
import DegreeLevel from '../../../types/DegreeLevel';
import { RangeType } from '../../../types/FilterValueType';
import { useFilter } from '../../../utils/useFilter';

import RangeFilter from '../RangeFilter';

export interface GraduateDegreeDateFilterProps {
  name: string;
}

export const generateQuery = ([minValue, maxValue]: RangeType): WhereQueryInterface => {
  const completeYearFilter = Object({ complete_year: { _lte: maxValue, _gte: minValue } });

  const queryGraduateDegreeDateFilter: WhereQueryInterface = {
    enabled: true,
    operator: '_and',
    whereQuery: {
      degrees: {
        degree_level: { _neq: DegreeLevel.UNDERGRAD },
        _and: completeYearFilter,
      },
    },
  };

  return queryGraduateDegreeDateFilter;
};

const GraduateDegreeDateFilter: React.FC<GraduateDegreeDateFilterProps> = ({ name }) => {
  const { storeFilterStatus, filterValue, clearFilter } = useFilter(name);

  const setFilterValue = (range: [number, number]) => storeFilterStatus(generateQuery(range), range);

  const bottomYear = new Date().getFullYear() - 10;
  const topYear = new Date().getFullYear() + 6;

  return (
    <RangeFilter
      precision={0}
      filterTitle={'Year Advanced Degree Graduation'}
      range={[bottomYear, topYear]}
      value={filterValue as RangeType}
      step={1}
      setFilterValue={setFilterValue}
      clearFilter={clearFilter}
    />
  );
};

export default GraduateDegreeDateFilter;
