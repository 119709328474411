import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import colors from 'elitegrad-common/src/theme/colors';
import { DefaultCandidatePhoto } from 'elitegrad-common/src/assets/images';
import FullTimeInterestTag from 'elitegrad-common/src/components/FullTimeInterestTag';
import InternshipInterestTag from 'elitegrad-common/src/components/InternshipInterestTag';
import Candidate, { isFullTime, isInternship } from 'elitegrad-common/src/types/Candidate';
import CheckboxCard from 'elitegrad-common/src/components/CheckboxCard';
import Degree from 'elitegrad-common/src/types/Degree';
import { formatMonthYear } from 'elitegrad-common/src/utils/dateUtils';
import ToggleBlockMenuOption from 'elitegrad-common/src/components/candidates/ToggleBlockMenuOption';

import CandidateCardDotMenu from './CandidateCardDotMenu';

const StyledHeader = styled.div`
  padding: 0 0 1.2rem;
  min-height: 15.5rem;
`;

const StyledCandidateDetails = styled.div`
  margin: 1rem 0 0.5rem;

  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.4rem;
  text-align: center;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    opacity: 0.4;
  }
`;

const StyledCandidateImg = styled.img<{ selected: boolean }>`
  display: block;
  width: 7rem;
  height: 7rem;
  margin: 1rem auto 0;

  border: 2px solid;
  border-color: ${({ selected }) => (selected ? colors.green : colors.red)};

  border-radius: 50%;

  &:hover {
    opacity: 0.4;
  }
`;

const StyledDegreeYear = styled.div`
  text-align: center;

  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1rem;

  color: ${colors.gray_dark};
`;

const StyledTags = styled.div`
  grid-area: tags;

  & > * {
    margin-right: 0.4rem;
  }

  & > *:last-child {
    margin-right: 0;
  }
`;

const StyledTopBar = styled.div`
  display: grid;
  grid-template-columns: 2rem 1fr 2.4rem;
  grid-template-areas: 'radio tags menu';
  justify-items: center;
  align-items: center;
`;

export interface CandidateCardHeaderProps {
  candidate: Candidate;
  candidatePath: string;
  currentDegree?: Degree;

  toggleSelection?: (status: boolean) => void;
  selected: boolean;
  refetch: () => void;
}

const CandidateCardHeader: React.FC<CandidateCardHeaderProps> = ({
  candidate,
  currentDegree,
  toggleSelection,
  selected,
  refetch,
  candidatePath,
}) => {
  return (
    <StyledHeader>
      <StyledTopBar>
        {toggleSelection && <CheckboxCard onChange={toggleSelection} checked={selected} />}

        <StyledTags>
          {(isFullTime(candidate) && <FullTimeInterestTag />) || (isInternship(candidate) && <InternshipInterestTag />)}
        </StyledTags>
        <CandidateCardDotMenu refetch={refetch} candidate={candidate}>
          {candidate.blocked !== undefined && candidate.blockedReason !== undefined && (
            <ToggleBlockMenuOption
              refetch={refetch}
              candidateBlocked={candidate.blocked}
              candidateId={candidate.userId}
              reason={candidate.blockedReason}
            />
          )}
        </CandidateCardDotMenu>
      </StyledTopBar>

      <Link to={candidatePath}>
        <StyledCandidateImg selected={selected} src={candidate.photo || DefaultCandidatePhoto} />
      </Link>
      <Link to={candidatePath}>
        <StyledCandidateDetails>
          {candidate.fullname}
          {candidate.title ? `, ${candidate.title}` : ''}
        </StyledCandidateDetails>
      </Link>
      {currentDegree && (
        <StyledDegreeYear>{currentDegree.completeDate && formatMonthYear(currentDegree.completeDate)}</StyledDegreeYear>
      )}
    </StyledHeader>
  );
};

export default CandidateCardHeader;
