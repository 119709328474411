import React from 'react';
import styled from 'styled-components';

import Modal from '../../components/Modal';

import { colors } from '../../theme';
import SecondaryButton from '../../components/SecondaryButton';
import PrimaryButton from '../../components/PrimaryButton';

const StyledTitle = styled.div`
  padding: 2rem 1rem;

  background: ${colors.gray_light_l1};
  border-bottom: 1px solid ${colors.gray_light};

  font-size: 2rem;
  font-weight: 700;
`;

const StyledModal = styled.div`
  &&& > * > * {
    height: 30rem;
  }
`;

const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem;
`;

const StyledButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  margin: 3rem 0;
`;

const StyledReason = styled.div`
  padding: 2rem 1rem;

  font-weight: 700;
  color: ${colors.red};
`;

export interface UnBlockMenuModalProps {
  unBlock: () => void;
  reason: string;
  setModalOpen: (status: boolean) => void;
}

const UnBlockMenuModal: React.FC<UnBlockMenuModalProps> = ({ setModalOpen, unBlock, reason }) => {
  const handleUnblock = () => {
    setModalOpen(false);
    unBlock();
  };

  return (
    <StyledModal>
      <Modal setModalOpen={setModalOpen}>
        <StyledTitle>You are about to un-block a candidate</StyledTitle>
        <StyledModalContent>
          This candidate was blocked by the following reason:
          <StyledReason>{reason}</StyledReason>
          <StyledButtons>
            Are you sure you want to un-block this candidate
            <SecondaryButton big onClick={() => setModalOpen(false)} text={'No, Cancel'} />
            <PrimaryButton dark big onClick={handleUnblock} text={'Yes, unblock'} />
          </StyledButtons>
        </StyledModalContent>
      </Modal>
    </StyledModal>
  );
};

export default UnBlockMenuModal;
