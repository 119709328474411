import FunFact from '../types/FunFact';
import { FunFactParts } from '../generated/FunFactParts';

const asFunFact = (funFactParts: FunFactParts): FunFact => {
  return {
    id: funFactParts.fun_fact_id,
    description: funFactParts.description,
  };
};

export { asFunFact };
