import gql from 'graphql-tag';

const query = gql`
  query ExistCV($userId: String!) {
    grad_upload_doc_aggregate(where: { user_id: { _eq: $userId } }) {
      aggregate {
        count(distinct: true)
      }
    }
  }
`;

export default query;
