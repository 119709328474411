import gql from 'graphql-tag';
import { CVParts } from './fragments.gql';

const query = gql`
  query DownloadCV($userId: String!) {
    grad_upload_doc(where: { user_id: { _eq: $userId } }) {
      ...CVParts
    }
  }
  ${CVParts}
`;

export default query;
