import React from 'react';
import styled from 'styled-components';

import { colors } from 'elitegrad-common/src/theme';
import Header from 'elitegrad-common/src/components/Header';
import Pagination, { PaginationState } from 'elitegrad-common/src/components/pagination';
import SpinnerIsoType from 'elitegrad-common/src/components/SpinnerIsoType';
import ShowPhotosSwitch from 'elitegrad-common/src/components/ShowPhotosSwitch';
import Candidate from 'elitegrad-common/src/types/Candidate';

import Page from 'views/Page';
import { candidatePath } from 'routes';
import CandidatesGrid from './CandidatesGrid';
import FilterSideBar from './FilterSideBar';

const StyledCount = styled.div`
  margin-right: 1rem;
  font-size: 1.2rem;
  line-height: 1.2rem;

  color: ${colors.gray_dark};

  &&& {
    padding: 0 2rem 1rem;
  }
`;

const StyledPaginationWrapper = styled.div`
  text-align: center;
`;

export interface CandidatesProps {
  refetch: () => void;

  candidates: Candidate[];
  loadingCandidates: boolean;
  pagination: PaginationState;
  onChangePaginationOffset: (offset: number) => void;
}

const Candidates: React.FC<CandidatesProps> = ({
  candidates,
  loadingCandidates,
  pagination,
  onChangePaginationOffset,
  refetch,
}) => {
  const headerComponent = (
    <Header title={'Candidates'}>
      <ShowPhotosSwitch />
    </Header>
  );
  return (
    <Page extraSidebar={<FilterSideBar />} headerComponent={headerComponent}>
      {loadingCandidates ? (
        <SpinnerIsoType text="Loading" />
      ) : (
        <>
          <StyledCount>
            {loadingCandidates
              ? ''
              : `Showing ${candidates.length} candidate${candidates.length === 1 ? '' : 's'} of ${pagination.count}`}
          </StyledCount>
          <CandidatesGrid
            candidatePathGenerator={(c: Candidate) => candidatePath(c.userId)}
            refetch={refetch}
            candidates={candidates}
          />
          <StyledPaginationWrapper>
            <Pagination pagination={pagination} onPageChange={onChangePaginationOffset} />
          </StyledPaginationWrapper>
        </>
      )}
    </Page>
  );
};

export default Candidates;
