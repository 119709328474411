import React from 'react';
import styled, { css } from 'styled-components';

import { SquaresSpinner, EGIsotype } from '../assets/vectors';
import colors from '../theme/colors';

const StyledSpinner = styled.div<{ fullPage: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ fullPage }) =>
    fullPage &&
    css`
      height: 100vh;
    `}
`;

const StyledElements = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;

  & > *:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

const StyledIsotype = styled(EGIsotype)`
  width: 100%;
  height: 7.4rem;
  padding: 1.4rem 1rem;

  & * {
    fill: ${colors.red};
    stroke: ${colors.red};
  }
`;

const StyledSpinnerSquares = styled(SquaresSpinner)`
  width: 1.8rem;
  height: 1.8rem;
`;

const StyledText = styled.span`
  height: 1.4rem;
  font-size: 1.2rem;
  line-height: 1.4rem;
`;

interface SpinnerProps {
  text: string;
  fullPage?: boolean;
}

const SpinnerIsoType: React.FC<SpinnerProps> = ({ fullPage = false, text }) => {
  return (
    <StyledSpinner fullPage={fullPage}>
      <StyledElements>
        <StyledIsotype />
        <StyledSpinnerSquares />
        <StyledText>{`${text}${text ? ' ...' : ''}`}</StyledText>
      </StyledElements>
    </StyledSpinner>
  );
};

export default SpinnerIsoType;
