import Major from '../types/Major';

import { MajorSelectorParts } from '../generated/MajorSelectorParts';

const asMajor = (parts: MajorSelectorParts): Major => {
  return {
    id: parts.major_selection_id,
    name: parts.major,
  };
};

export { asMajor };
