import React from 'react';
import { Redirect } from 'react-router-dom';

import { useAuth } from 'elitegrad-common/src/utils/react-auth';

const AuthDebug: React.FC = () => {
  const { authenticating, checkingSession, error, loginRequired, user } = useAuth();

  if (loginRequired) {
    return <Redirect to="/auth/login" />;
  }

  return (
    <div>
      {authenticating && <div>Authenticating ...</div>}
      {checkingSession && <div>Checking session...</div>}
      <div>loginRequired = {'' + loginRequired}</div>
      {user && (
        <div>
          {user.email} :: {user.sub}
        </div>
      )}
      {error && (
        <div>
          {error.error} :: {error.error_description}
        </div>
      )}
    </div>
  );
};

export default AuthDebug;
