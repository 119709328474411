import React from 'react';
import { WhereQueryInterface } from '../../../queries/queryUtils';
import AwardCategory from '../../../types/AwardCategory';
import AwardProfessionalDesignation from '../../../types/AwardProfessionalDesignation';
import { FilterOption } from '../../../types/FilterValueType';
import { useFilter } from '../../../utils/useFilter';
import MultiCheckFilter from '../MultiCheckFilter';

export interface ProfessionalDesignationFilterProps {
  name: string;
}

export const generateQuery = (options: FilterOption[]): WhereQueryInterface => {
  const optionsValues = options.map(({ filterValue }) => filterValue);
  const queryProfessionalDesignationFilter: WhereQueryInterface = {
    enabled: !!optionsValues.length,
    operator: '_and',
    whereQuery: {
      awards: { award_category: { _eq: AwardCategory.PROFESSIONAL_DESIGNATION }, award_name: { _in: optionsValues } },
    },
  };

  return queryProfessionalDesignationFilter;
};

const ProfessionalDesignationFilter: React.FC<ProfessionalDesignationFilterProps> = ({ name }) => {
  const { storeFilterStatus, filterValue, clearFilter } = useFilter(name);

  const setFilterValue = (options: FilterOption[]) => storeFilterStatus(generateQuery(options), options);

  const optionsGroups: FilterOption[] = Object.values(AwardProfessionalDesignation).map((designation) => ({
    filterValue: designation,
    displayValue: designation,
    id: designation,
  }));

  return (
    <MultiCheckFilter
      filterTitle={'Groups'}
      options={optionsGroups}
      defaultOptions={(filterValue as FilterOption[]) || []}
      setFilterValue={setFilterValue}
      clearFilter={clearFilter}
    />
  );
};

export default ProfessionalDesignationFilter;
