import { LanguageSelectorParts } from '../generated/LanguageSelectorParts';
import SpokenLanguage from '../types/SpokenLanguage';

const asSpokenLanguage = (parts: LanguageSelectorParts): SpokenLanguage => {
  return {
    id: parts.spoken_language_id,
    language: parts.language,
  };
};

export { asSpokenLanguage };
