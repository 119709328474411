import React from 'react';
import { useQuery } from '@apollo/client';

import GET_INTEREST_JOB_TYPE_SELECTION from '../../../queries/getInterestJobTypeSelection.gql';
import { GetInterestJobTypeSelection } from '../../../generated/GetInterestJobTypeSelection';
import { WhereQueryInterface } from '../../../queries/queryUtils';
import { selectionAsInterestJobType } from '../../../transformers/interestJobTypesSelection';
import { FilterOption } from '../../../types/FilterValueType';
import InterestJobType from '../../../types/InterestJobType';
import { useFilter } from '../../../utils/useFilter';
import Spinner from '../../Spinner';
import MultiCheckFilter from '../MultiCheckFilter';

export interface InterestJobTypesFilterProps {
  name: string;
}

export const generateQuery = (options: FilterOption[]): WhereQueryInterface => {
  const values = options.map(({ filterValue }) => filterValue);
  const queryInterestJobTypesFilter: WhereQueryInterface = {
    enabled: !!values.length,
    operator: '_and',
    whereQuery: { interest_job_types: { job_type: { _in: values } } },
  };
  return queryInterestJobTypesFilter;
};

const InterestJobTypesFilter: React.FC<InterestJobTypesFilterProps> = ({ name }) => {
  const { storeFilterStatus, filterValue, clearFilter } = useFilter(name);

  const { data, loading } = useQuery<GetInterestJobTypeSelection>(GET_INTEREST_JOB_TYPE_SELECTION);

  const setFilterValue = (options: FilterOption[]) => storeFilterStatus(generateQuery(options), options);

  const firmTypes: InterestJobType[] =
    data?.grad_interest_job_type_selection.map((gp) => selectionAsInterestJobType(gp)) || [];
  const optionsFirmTypes: FilterOption[] = firmTypes.map(({ id, jobType }) => ({
    filterValue: jobType,
    displayValue: jobType,
    id: id,
  }));

  return loading ? (
    <Spinner />
  ) : (
    <MultiCheckFilter
      filterTitle={'Interest job types'}
      options={optionsFirmTypes}
      defaultOptions={(filterValue as FilterOption[]) || []}
      setFilterValue={setFilterValue}
      clearFilter={clearFilter}
    />
  );
};

export default InterestJobTypesFilter;
