import { TestScoreParts } from '../generated/TestScoreParts';

import Test from '../types/Test';
import TestType from '../types/TestType';
import { asTestDetail } from './testDetails';

const asTestType = (testType: string): TestType => {
  if ((Object.values(TestType) as string[]).includes(testType)) {
    return testType as TestType;
  }

  // eslint-disable-next-line no-console
  console.warn(`Unknown test type: ${testType}`);
  return TestType.UNKNOWN;
};

const asTest = (parts: TestScoreParts): Test => {
  const type = asTestType(parts.test);
  const isSATorACT = type === TestType.ACT || type === TestType.SAT;
  const details = isSATorACT ? parts.test_score_details.map((d) => asTestDetail(d, type)) : [];
  return {
    id: parts.test_score_id,
    score: parts.score,
    outof: parts.outof,
    type: type,
    details: details,
  };
};

export { asTest };
