import React from 'react';

import TextInput, { TextInputProps } from './TextInput';

type PasswordInputProps = Omit<TextInputProps, 'type'>;

const PasswordInput: React.FC<PasswordInputProps> = (props) => {
  return <TextInput type="password" {...props} />;
};

export default PasswordInput;
