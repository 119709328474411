import { grad_profile_bool_exp } from '../generated/globalTypes';

export interface WhereQueryInterface {
  enabled: boolean;
  operator: '_and' | '_not' | '_or'; // Currently only supports and
  whereQuery: grad_profile_bool_exp;
}

export const generateWhereQuery = (queryParams: WhereQueryInterface[]): grad_profile_bool_exp | null => {
  const onlyEnabledQuery = queryParams.filter(({ enabled }) => enabled);
  if (!onlyEnabledQuery.length) return null;
  return generateWhereQueryStep(onlyEnabledQuery);
};

const generateWhereQueryStep = (queryParams: WhereQueryInterface[]): grad_profile_bool_exp => {
  const [firstQuery, ...newRest] = queryParams;
  const { operator, whereQuery } = firstQuery;
  return newRest.length
    ? { [operator]: { ...whereQuery, ...generateWhereQueryStep(newRest) } }
    : { [operator]: whereQuery };
};
