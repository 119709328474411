import EmployerPreference from '../types/EmployerPreference';
import { EmployerPreferencesParts } from '../generated/EmployerPreferencesParts';

const asEmployerPreferences = (employerPreferencesParts: EmployerPreferencesParts): EmployerPreference => {
  return {
    id: employerPreferencesParts.employer_preference_id,
    preference: employerPreferencesParts.preference,
    userId: employerPreferencesParts.user_id,
  };
};

export { asEmployerPreferences };
