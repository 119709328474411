import React from 'react';
import CandidateCardDotMenuOption from './CandidateCardDotMenuOption';
import { MenuMoreDownloadCV } from '../../assets/vectors';

interface DownloadCVMenuOptionProps {
  onClick: () => void;
  cvExists: boolean;
  downloadingCV: boolean;
}
const DownloadCVMenuOption: React.FC<DownloadCVMenuOptionProps> = ({ cvExists, downloadingCV, onClick }) => {
  return (
    <CandidateCardDotMenuOption
      disabled={!cvExists || downloadingCV}
      text={'Download Resume'}
      icon={MenuMoreDownloadCV}
      onClick={onClick}
    />
  );
};

export default DownloadCVMenuOption;
