import ComputerLanguageSelectionType from '../types/ComputerLanguageSelection';
import { ComputerLanguageSelectorParts } from '../generated/ComputerLanguageSelectorParts';

const selectionAsComputerLanguageSelection = (
  technicalLanguage: ComputerLanguageSelectorParts,
): ComputerLanguageSelectionType => {
  return {
    language: technicalLanguage.computer_language,
  };
};

export { selectionAsComputerLanguageSelection };
