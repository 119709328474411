import gql from 'graphql-tag';

import { CandidateImageOnlyParts } from './fragments.gql';

export default gql`
  query GetProfileImagesByUserIds($userIds: [String!]!) {
    grad_profile(where: { user_id: { _in: $userIds } }) {
      ...CandidateImageOnlyParts
    }
  }
  ${CandidateImageOnlyParts}
`;
